import React from 'react';
import styled from 'styled-components';
// import LogoSection from './LogoSection';
import FooterColumn from './FooterColumn';

const FooterContainer = styled.footer`
  align-content: flex-start;
  flex-wrap: wrap;
  border-top: 1px solid rgba(217, 217, 217, 1);
  background-color: var(--sds-color-background-default-default);
  padding: 32px 32px 80px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const useCasesData = [
  'UI design', 'UX design', 'Wireframing', 'Diagramming',
  'Brainstorming', 'Online whiteboard', 'Team collaboration'
];

const exploreData = [  
  'Design', 'Prototyping', 'Development features', 'Design systems',
  'Collaboration features', 'Design process', 'FigJam'
];

const resourcesData = [
  'Blog', 'Best practices', 'Colors', 'Color wheel',
  'Support', 'Developers', 'Resource library'
];

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterColumn title="Use cases" items={useCasesData} />
        <FooterColumn title="Explore" items={exploreData} />
        <FooterColumn title="Resources" items={resourcesData} />
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;