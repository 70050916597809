
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import {React}  from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import { SessionProvider } from './context/SessionContext.js';
import  GraphAPITokenComponent  from './Components/GraphAPITokenComponent.js';

import {FunctionsProvider } from './context/FunctionContext.js';
import {NotificationProvider } from './context/NotificationContext.js';
import NotificationBox from './Components/NotificationBox.js';
import * as Sentry from "@sentry/react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
  import {Button  } from '@mui/material';
  import { loginRequest } from './authConfig';
  import LandingPage from './Components/Landing.js'; // Import LandingPage component
import LoginPage from './Components/LoginPage.js'; // Import LandingPage component
import TestFunctionsComponent from './Components/TestFunctionsComponent.js'; // Import LandingPage component
import Comp from './Components/Comp.js'; // Import LandingPage component#
import UsersList from './Components/UsersList.js'; // Import LandingPage component
//import { ErrorBoundary } from  "@sentry/react";
import DefaultPage from './pages/DefaultPage.js';
//import { useIsAuthenticated } from "@azure/msal-react";


//import { ErrorBoundary } from "react-error-boundary";

Sentry.init({
  dsn: "https://d489a9d183d85ed58c1ff819ee339b62@o4508087991205888.ingest.de.sentry.io/4508087996514384",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^http:\/\/yourserver\.io\/api/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const theme = createTheme({
  palette: {
    primary: {
      main: '#712C9C',
    },
    secondary: {
      main: '#9A348E',
    },
    error: {
      main: '#ff1744',
    },
    background: {
      default: '#fff',
    },
  },
});


const MainContent = () => {
 
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
      instance
          .loginRedirect({
              ...loginRequest,
              prompt: 'create',
          })
          .catch((error) => console.log(error));
  };

 // const isAuthenticated = useIsAuthenticated();
  return (
      <div className="App">
          <AuthenticatedTemplate>
              {activeAccount ? (
                 <Routes>
              
                 <Route path="/" element={<LandingPage />} />  {/* Include LandingPage component */}
               <Route path="defaultpage" element={<DefaultPage />} />  {/* Include LandingPage component */}
                 <Route path="comp" element={<Comp />} /> {}
                 <Route path="login" element={<LoginPage />} />  {/* Include LandingPage component */}
                 <Route path="test" element={<TestFunctionsComponent />} />  {/* Include LandingPage component */}
                 <Route path="token" element={<GraphAPITokenComponent/>} />  {/*  */}
              
                 <Route path="UsersList" element={< UsersList />} />  {/* Include LandingPage component */}
                 <Route path="*" element={<DefaultPage />} />  {/* 404 Page */}
            
             
               </Routes>
              ) : null}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
              <Button className="signInButton" onClick={handleRedirect} variant="primary">
                  Sign up
              </Button>
          </UnauthenticatedTemplate>
      </div>
  );
};




//Sentry.captureMessage("Something wejhjkhjhknt wrong");

const App = ({msalInstance}) => {

 
  return (
    <MsalProvider instance={msalInstance}>
       <BrowserRouter>
          <ThemeProvider theme={theme}>
           <CssBaseline />
          <NotificationProvider>
           <SessionProvider>
           <FunctionsProvider >
           <NotificationBox />
            <div className="App">  
                <MainContent />      
            </div>
            </FunctionsProvider >
            </SessionProvider>
            </NotificationProvider>
          </ThemeProvider>
          </BrowserRouter>
        
         </MsalProvider>
          //  </ErrorBoundary>
  );
}


export default App;
