import apiService from './apiService';

export const fetchUsers = async () => {
  try {
    const data = await apiService.get('/Application');
    return data;
  } catch (err) {
    console.error('Error fetching users:', err);
    throw err;
  }
};

export const fetchUsersByAge = async (age) => {
  try {
    const data = await apiService.get(`/Application/${age}`);
    return data;
  } catch (err) {
    console.error('Error fetching users by age:', err);
    throw err;
  }
};