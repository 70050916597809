// components/LoginPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../hooks/useSession'; // Ensure this path is correct and the hook is properly implemented

const LoginPage = () => {
  const { setSessionData } = useSession();
  const navigate = useNavigate();
  const handleLogin = () => {
    // Simulate a login action
    const fakeUser = { name: 'John Doe', email: 'john@example.com' };
    const fakeSessionId = '123456789';

    setSessionData({
      user: fakeUser,
      sessionId: fakeSessionId,
      isAuthenticated: true,
    });
    navigate('/');
  };

  return (
    <div>
      <h2>Login Page</h2>
      <button onClick={handleLogin}>Log In</button>
    </div>
  );
};

export default LoginPage;
