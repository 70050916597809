import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
  import {  useMsal } from "@azure/msal-react";
  import { useState, useEffect } from "react";
  
  async function callApi(accessToken) {
    const response = await fetch("https://graph.microsoft.com/v1.0/me", {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch data from API");
    }
    return response.json();
  }

  function  GraphAPITokenComponent() {
    const { instance, inProgress, accounts } = useMsal();
    const [apiData, setApiData] = useState(null);
  
    useEffect(() => {
      if (!apiData && inProgress === InteractionStatus.None) {
        const accessTokenRequest = {
          scopes: ["user.read"],
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            callApi(accessToken).then((response) => {
              setApiData(response);
            });
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance
                .acquireTokenPopup(accessTokenRequest)
                .then(function (accessTokenResponse) {
                  // Acquire token interactive success
                  let accessToken = accessTokenResponse.accessToken;
                  // Call your API with token
                  callApi(accessToken).then((response) => {
                    setApiData(response);
                  });
                })
                .catch(function (error) {
                  // Acquire token interactive failure
                  console.log(error);
                });
            }
            console.log(error);
          });
      }
    }, [instance, accounts, inProgress, apiData]);
  
    return (
        <div>
            {apiData ? (
                <div>
                    <p>Display Name: {apiData.displayName}</p>
                    <p>Email: {apiData.mail}</p>
                    <p>Job Title: {apiData.jobTitle}</p>
                    {/* Add more fields as needed */}
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
  }
  


export default GraphAPITokenComponent;
 