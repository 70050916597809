import React from 'react';
import { ListItem as MuiListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ListItem = ({ imageUrl, title, description }) => {
  return (
    <MuiListItem>
      <ListItemAvatar>
        <Avatar src={imageUrl} alt={title} />
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={description}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="more">
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </MuiListItem>
  );
};

export default ListItem;