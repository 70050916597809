import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  TextField,
  Button,
  Box,
} from '@mui/material';

const SaveDialogBox = ({ open, onClose, files, onSave }) => {
  const [selectedFile, setSelectedFile] = React.useState('');
  const [fileName, setFileName] = React.useState('');

  const handleListItemClick = (file) => {
    setSelectedFile(file);
    setFileName(file);
  };

  const handleSave = () => {
    onSave(fileName);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Save File</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '70%', margin: '0 auto' }}>
          <List
            sx={{
              maxHeight: 200, // Limit the height
              overflow: 'auto', // Add scrollbar
              border: '1px solid #ccc', // Add border
              borderRadius: '4px' // Optional: Add border radius
            }}
          >
            {files.map((file, index) => (
              <ListItem
                button
                key={index}
                selected={selectedFile === file}
                onClick={() => handleListItemClick(file)}
              >
                {file}
              </ListItem>
            ))}
          </List>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            placeholder="Enter file name"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveDialogBox;
