import React from 'react';
import styled from 'styled-components';

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: var(--sds-color-text-default-default);
  font-weight: 400;
  line-height: 140%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ColumnTitle = styled.h3`
  font-family: Inter, sans-serif;
  padding-bottom: 16px;
  font-weight: 600;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const ColumnItem = styled.a`
  font-family: Inter, sans-serif;
  margin-top: 12px;
  justify-content: center;
  padding: 4px 0;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

function FooterColumn({ title, items }) {
  return (
    <ColumnWrapper>
      <ColumnContent>
        <ColumnTitle>{title}</ColumnTitle>
        {items.map((item, index) => (
          <ColumnItem key={index} href="#">{item}</ColumnItem>
        ))}
      </ColumnContent>
    </ColumnWrapper>
  );
}

export default FooterColumn;