import React from 'react';
import { Grid, Checkbox, Typography } from '@mui/material';
import { withJsonFormsControlProps } from '@jsonforms/react';
import JsonTreeView from '../Profilem';

const CustomBooleanControl = (props) => {
  const { data, handleChange, path, label } = props;

  return (
    <>
    <JsonTreeView data={props.schema.plan} />
  
    <Grid container spacing={1}>
      <div>a
        {path}
        a
      </div>
      <Grid item xs={12}>
        <Checkbox
          checked={!!data} // Convert data to boolean
          onChange={(event) => handleChange(path, event.target.checked)} />
        <label>{label}</label>
      </Grid>
      {data && (
        <Grid item xs={12}>
          <Typography>Additional info when checked!</Typography>
        </Grid>
      )}
    </Grid></>
  );
};

// Wrap the custom control with `withJsonFormsControlProps` to connect with JsonForms
export default withJsonFormsControlProps(CustomBooleanControl);