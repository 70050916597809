import React from 'react';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

function JsonTreeView({ data }) {
  const renderTree = (nodes, path = '') =>
    Object.keys(nodes).map((key) => {
      const currentPath = path ? `${path}.${key}` : key;
      if (typeof nodes[key] === 'object' && nodes[key] !== null) {
        return (
            <TreeItem itemId={currentPath} nodeId={currentPath} label={key} onClick={() => handleNodeClick(currentPath, nodes[key])}> 

            {renderTree(nodes[key], currentPath)}
          </TreeItem>
        );
      }
      return <TreeItem itemId={currentPath} nodeId={currentPath} label={key} onClick={() => handleNodeClick(currentPath, nodes[key])}/>;
    });


    
    const handleNodeClick = (nodeId, value) => {  // This function is called when a node is clicked
        console.log('Selected node path:', nodeId);
    };

  const handleNodeSelect = (event, nodeId) => {
    console.log('Selected node path:', nodeId);
  };

  return (
    <SimpleTreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      onNodeSelect={handleNodeSelect}
    >
      {renderTree(data)}
    </SimpleTreeView>
  );
}

export default JsonTreeView;
