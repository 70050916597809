import React from 'react';
import { List, Paper, Stack } from '@mui/material'; // Import Stack component
import ListItem from './ListItem';
import Divider from './Divider';

const listItems = [
  {
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/83e21a21093403d91ae5c35e09af20f3f1b0582cbbdfef7b438cc90aee54eb9a?apiKey=beebf2ac1d5c4e5e8f2c6793c531fa63&",
    title: "List item",
    description: "Supporting line text lorem ipsum dolor sit amet, consectetur."
  },
  {
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/83e21a21093403d91ae5c35e09af20f3f1b0582cbbdfef7b438cc90aee54eb9a?apiKey=beebf2ac1d5c4e5e8f2c6793c531fa63&",
    title: "List item",
    description: "Supporting line text lorem ipsum dolor sit amet, consectetur."
  },
  {
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/83e21a21093403d91ae5c35e09af20f3f1b0582cbbdfef7b438cc90aee54eb9a?apiKey=beebf2ac1d5c4e5e8f2c6793c531fa63&",
    title: "List item",
    description: "Supporting line text lorem ipsum dolor sit amet, consectetur."
  },
  {
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/83e21a21093403d91ae5c35e09af20f3f1b0582cbbdfef7b438cc90aee54eb9a?apiKey=beebf2ac1d5c4e5e8f2c6793c531fa63&",
    title: "List item",
    description: "Supporting line text lorem ipsum dolor sit amet, consectetur."
  },
  {
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/83e21a21093403d91ae5c35e09af20f3f1b0582cbbdfef7b438cc90aee54eb9a?apiKey=beebf2ac1d5c4e5e8f2c6793c531fa63&",
    title: "List item",
    description: "Supporting line text lorem ipsum dolor sit amet, consectetur."
  }
];

const ListContainer = () => {
  return (
    <Stack spacing={3}> 
   <Paper elevation={3}>
      <List>
        {listItems.map((item, index) => (
          <React.Fragment key={index}>
            <Stack spacing={3}> 
            <ListItem
              imageUrl={item.imageUrl}
              title={item.title}
              description={item.description}
            />
            {index < listItems.length - 1 && <Divider />}
         </Stack>
          </React.Fragment>
        ))}
      </List>
    </Paper>
    </Stack>
  );
};

export default ListContainer;