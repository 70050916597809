import React, { useState, useEffect } from 'react';
import { Snackbar, Button } from '@mui/material';
const CookieDisclaimer = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const disclaimerAccepted = localStorage.getItem('disclaimerAccepted');
    if (!disclaimerAccepted) {
      setOpen(true);
    }
  }, []);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    localStorage.setItem('disclaimerAccepted', 'true');
    setOpen(false);
  };
///dfsdfdf
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      message="We use cookies to improve your experience. By continuing to use our site, you accept our use of cookies."
      action={
        <Button color="secondary" size="small" onClick={handleClose}>
          Accept
        </Button>
      }
    />
  );
};
export default CookieDisclaimer;