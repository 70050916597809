// src/api/apiService.js
import axios from 'axios';

const API_BASE_URL = "http://localhost:900"     // process.env.API_BASE_URL // Replace with your API base URL

// Example function to get data
export const getData = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/notes`);
    console.log('Data fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Example function to post data
export const postData = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/data`, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
