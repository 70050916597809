import React, { useState } from 'react';


function SampleError () {



    const [throwError, setThrowError] = useState(false);
    const [count] = useState(0);
  
    if (throwError) {
      throw new Error('Count reached 2, something went wrong!');
      ;
  }

  return (

        <div> 
              <p>
              <button onClick={() => setThrowError(true)}>Trigger Error</button>
              {count}
              </p>
          </div>

  );
};



export default SampleError;