import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useNotification } from '../context/NotificationContext';

const NotificationBox = () => {
  const { notification, closeNotification } = useNotification();

  return (
    <Dialog
      open={notification.open}
      onClose={closeNotification}
      PaperProps={{
        style: {
          marginTop: '0',
          top: '0',
          position: 'absolute',
        },
      }}
    >
      <DialogTitle>Notification</DialogTitle>
      <DialogContent>
        <hr />
        <p>{notification.message}</p>
   
      </DialogContent>
      <DialogActions>
        <Button onClick={closeNotification} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationBox;