import React from 'react';
import { useFunctions } from '../context/FunctionContext';


const TestFunctionsComponent = () => {
  const {
    getAllFunctions,
    findFunctionByGuid,
    addFunction,
    updateFunctionByGuid,
    deleteFunctionByGuid,
    populateFunctionsFromJson,
    convertFunctionsToJson
  } = useFunctions();

  return (
    <div>
      <button onClick={() => addFunction({ Name: 'Test Function', Guid: '123', VariableType: 'String' })}>
        Add Function
      </button>
      <button onClick={() => deleteFunctionByGuid('123')}>Delete Function</button>
      <button onClick={() => console.log(getAllFunctions())}>Show All Functions</button>
      <button onClick={() => console.log(findFunctionByGuid('123'))}>Find Function</button>
      <button onClick={() => updateFunctionByGuid('123', { Name: 'Updated Function' })}>
        Update Function
      </button>
      <button onClick={() => populateFunctionsFromJson('[{"Name": "JSON Function", "Guid": "456", "VariableType": "Number"}]')}>
        Populate Functions
      </button>
      <button onClick={() => console.log(convertFunctionsToJson())}>Convert Functions to JSON</button>
    </div>
  );
};

export default TestFunctionsComponent;