
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Chip, Stack, Box, TextField, InputAdornment ,  Dialog, DialogTitle, DialogContent, DialogActions  } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logger from '../logger';
import SearchIcon from '@mui/icons-material/Search';
import { useSession } from '../hooks/useSession';
import CookieDisclaimer from './CookieDisclaimer';
import SaveDialogBox from './SaveDialogBox';
import Footer from './Footer';

import ListContainer from './ListContainer';
import { useNotification } from '../context/NotificationContext';
import Container from '@mui/material/Container';
import { getData } from '../Services/api';
import { JsonForms } from '@jsonforms/react';
//import JsonTreeView from './Profilem';
//import JsonEditorComponent from './jsonview';
//import ListboxRenderer from './ListboxRenderer';
import customBooleanControlTester  from './Render/controlTester';
import CustomBooleanControl from './Render/jsonformcustom';
import jsonformcustomlistbox  from './Render/jsonformcustomlistbox';
import jsonformcustomlistboxtester from './Render/jsonformcustomlistboxtester';
import SampleError from './ErrorSamping';

//import MyFallbackComponent from '../MyFallbackComponent';

//import { rankWith, isEnumControl } from '@jsonforms/core';
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers'


//const listboxTester = rankWith(3, isEnumControl);

const renderers = [
 ...materialRenderers,
  // register custom renderers
  { tester: customBooleanControlTester, renderer: CustomBooleanControl},
  { tester: jsonformcustomlistboxtester, renderer: jsonformcustomlistbox}
];

//function BuggyComponent() {
//  const [throwError, setThrowError] = useState(false);

 // if (throwError) {
  //  throw new Error('This is a test error');
 // }

  //return <button onClick={() => setThrowError(true)}>Trigger Error</button>;
//}





function LandingPage() {
    const { sessionData } = useSession();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
   // <AppBar position="static" color="primary"  sx={{ borderRadius: 2  , mt: 0,   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
    
   const datax =  { multiselect: [
    { id: 1, text: "Item 1" },
    { id: 2, text: "Item 2" },
    { id: 3, text: "Item 3" },
  ]}
  
  const { showNotification } = useNotification();


   const [data, setData] = useState(datax);  // this is used to save the output data from
   useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  useEffect(() => {
    logger.debug('App component mounted');
    logger.info('This is an info message');
    logger.warn('This is a warning message');
    logger.error('This is an error message');
  }, []);


  const schema = {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        minLength: 1,
      },
      rating: {
        type: 'string',
        enum: ['Never', 'Daily', 'Weekly', 'Monthly'],
        plan: {
          user: {
            id: 1,
            name: 'John Doe',
            contact: {
              email: 'john@example.com',
              phone: '123-456-7890',
            },
          },
          posts: [
            { id: 1, title: 'Hello World' },
            { id: 2, title: 'React Rocks' },
          ],
        }
      },
      multiselect:{
       
      },

      done: {
        type: 'boolean',
      },
      due_date: {
        type: 'string',
        format: 'date',
      },
      recurrence: {
        type: 'string',
        enum: ['Never', 'Daily', 'Weekly', 'Monthly'],
      },
    },
    required: ['name', 'due_date'],
  };
  const uischema = {
    type: 'VerticalLayout',
    elements: [
      {
        type: 'Control',
        label: false,
        scope: '#/properties/done',
      },
      {
        type: 'Control',
        label: false,
        scope: '#/properties/rating',
      },
      {
        type: 'Control',
        label: false,
        scope: '#/properties/multiselect',
      },
      {
        type: 'Control',
        scope: '#/properties/name',
      },       {
            type: 'Control',
            scope: '#/properties/due_date',
          },
          {
            type: 'Control',
            scope: '#/properties/recurrence',
          }
       
    
    ],
  };
 // const initialData = {};
 //  const [data1, setData1] = useState(initialData);
 const throwKnownError = () => {
  throw new Error("This is an error from sentry tutorial");
};
 //const jsonData = {
  //user: {
   // id: 1,
   // name: 'John Doe',
    //contact: {
    //  email: 'john@example.com',
     // phone: '123-456-7890',
   // },
  //},
  //posts: [
   // { id: 1, title: 'Hello World' },
   // { id: 2, title: 'React Rocks' },
 // ],
//};


//#region
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const files = ['file1.txt', 'file2.doc', 'file3.pdf','file1.txt', 'file2.doc', 'file3.pdf','file1.txt', 'file2.doc', 'file3.pdf'];

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSaveFile = (fileName) => {
    console.log('File saved:', fileName);
    // Perform save action here
  };

//
  return (
    <div>
      <AppBar position="static" color="primary"  >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            aria-controls="menu-appbar"w
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ flex: 1 }}></div>
         
          <TextField
            variant="outlined"
            placeholder="Search..."
            size="small"
            sx={{  background: 'white',  width: '50%',
              borderRadius: '20px', // Adjust this value to achieve the desired roundness
              '& .MuiOutlinedInput-root': {
                borderRadius: '20px' // Ensures the input field itself also has rounded edges
              }



             }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Menu 1</MenuItem>
            <MenuItem onClick={handleClose}>Menu 2</MenuItem>
            <MenuItem onClick={handleClose}>Menu 3</MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Title
          </Typography>
          
          <Stack direction="row" spacing={1}>
            <Chip label="Chip 1" color="secondary" onClick={handleOpenModal} />
            <Chip label="Chip 2" color="secondary" />
            <Chip label="Chip 3" color="secondary" />
          </Stack>
        </Toolbar>
      </AppBar>
      <AppBar position="static" color="secondary" elevation={0} sx={{ mt: 0 }}>
        <Toolbar variant="dense">
          {/* Sub-menu content */}
          <Button color="inherit">Sub-menu 1</Button>
          <Button color="inherit">Sub-menu 2</Button>
          <Button color="inherit">Sub-menu 3</Button>
        </Toolbar>
      </AppBar>

      <div>
  dsadasdsadsadadasd
            {sessionData.isAuthenticated ? sessionData.user.name : 'Guest'}
  dasdasdasd
        <Box
          sx={{
            height: 200, // Adjust height as needed
            backgroundImage: 'url("./assets/hero.jpg")', // Step 2: Set your image path here
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            
            alignItems: 'center',
            border: '1px solid #000', // Example: 3px solid black border
            // Optional: if you want rounded corners
          }}
        >
          <Typography variant="h2" component="h1" color="primary.contrastText">
            Welcome
      
          </Typography>
              <Button onClick={() => showNotification('This is a notification message!')} color="primary">
            Show Notification
          </Button>
          <button onClick={() => throwKnownError()}>Break the world</button>;
        </Box>
        <Container color="primary" maxWidth="false" sx={{ p: 1,  pb: 5, pt: 5}}> 
      
        <Box component="section" sx={{ p: 3, border: '1px dashed grey', textAlign: 'left', backgroundColor: 'prim' }}>
    
            Welcome
            This Box renders as an HTML section element.
            aa
        
bb
            <span>{process.env.REACT_APP_API_URL}</span>
    </Box>
    <div>
    
  
 
   
   
      <SampleError />
   

    
    
    
      <button onClick={handleOpenDialog}>Open Save Dialog</button>
      <SaveDialogBox
        open={isDialogOpen}
        onClose={handleCloseDialog}
        files={files}
        onSave={handleSaveFile}
      />



      
    </div>  
      </Container>
      <Container maxWidth="false" sx={{ p: 1,  pb: 5, pt: 5}}> 
      <ListContainer/>
      </Container>
      </div>


 

<Footer/>
<CookieDisclaimer />

        <Dialog open={openModal} onClose={handleCloseModal} sx={{ '& .MuiDialog-paper': { width: '50%', maxWidth: 'none' } }}>
        <DialogTitle>Modal Title</DialogTitle>
        <DialogContent>

        <div>
      <h1>JSON Tree View</h1>

    </div>
        <JsonForms
              schema={schema}
              uischema={uischema}
              data={data}
              renderers={renderers}
              cells={materialCells}
              onChange={({ data, _errors }) => setData(data)}
    />
    <div>
    
    </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LandingPage;