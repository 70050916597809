import React, { useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
  Box,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import './styles.css';

const initialItems = [
  { id: 1, text: "Item 1" },
  { id: 2, text: "Item 2" },
  { id: 3, text: "Item 3" },
];

function Comp() {
  const [items, setItems] = useState(initialItems);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [newItemText, setNewItemText] = useState("");

  // Handle opening and closing the "Add Item" dialog
  const handleOpenAddDialog = () => setIsAddDialogOpen(true);
  const handleCloseAddDialog = () => setIsAddDialogOpen(false);

  // Handle adding a new item
  const handleAddItem = () => {
    if (newItemText.trim()) {
      setItems([
        ...items,
        { id: items.length + 1, text: newItemText },
      ]);
    }
    setNewItemText("");
    handleCloseAddDialog();
  };

  // Handle opening and closing the "Delete Item" confirmation dialog
  const handleOpenDeleteDialog = () => setIsDeleteDialogOpen(true);
  const handleCloseDeleteDialog = () => setIsDeleteDialogOpen(false);

  // Handle deleting the selected item
  const handleDeleteItem = () => {
    setItems(items.filter(item => item.id !== selectedItem.id));
    setSelectedItem(null);
    handleCloseDeleteDialog();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      p={2}
    >
      <Box
        border={1}
        borderColor="grey.400"
        borderRadius={4}
        width="300px"
        height="600px"
        overflow="auto"
        p={1}
        className="custom-scrollbar"
        flexGrow={1}
      >
        <List>
          {items.map(item => (
            <ListItem
              key={item.id}
              button
              selected={selectedItem && selectedItem.id === item.id}
              onClick={() => setSelectedItem(item)}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box display="flex" justifyContent="flex-start" mt={2} marginTop="10px">
        <Button variant="contained" onClick={handleOpenAddDialog} style={{ marginRight: '8px' }}>
          Add Item
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpenDeleteDialog}
          disabled={!selectedItem}
          startIcon={<DeleteIcon />}
        >
          Delete Item
        </Button>
      </Box>

      {/* Add Item Dialog */}
      <Dialog open={isAddDialogOpen} onClose={handleCloseAddDialog}>
        <DialogTitle>Add New Item</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Item Text"
            fullWidth
            value={newItemText}
            onChange={(e) => setNewItemText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddItem} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{selectedItem?.text}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteItem} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Comp;