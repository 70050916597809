import React, { createContext, useContext, useState } from 'react';

// Create the context
const NotificationContext = createContext();

// Custom hook to use the context
export const useNotification = () => {
  return useContext(NotificationContext);
};

// The provider component
export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({ open: false, message: '' });

  const showNotification = (message) => {
    setNotification({ open: true, message });
  };

  const closeNotification = () => {
    setNotification({ open: false, message: '' });
  };

  return (
    <NotificationContext.Provider value={{ notification, showNotification, closeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};