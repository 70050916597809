import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context
const FunctionsContext = createContext();

// Custom hook to use the context
export const useFunctions = () => {
    return useContext(FunctionsContext);
};

// The provider component
export const FunctionsProvider = ({ children }) => {
    const [functions, setFunctions] = useState(() => {
        // Load functions from localStorage if available
        const savedFunctions = localStorage.getItem('functions');
        return savedFunctions ? JSON.parse(savedFunctions) : [];
    });

    // Save functions to localStorage whenever they are updated
    useEffect(() => {
        localStorage.setItem('functions', JSON.stringify(functions));
    }, [functions]);

    // Function to return all functions
    const getAllFunctions = () => {
        return functions;
    };

    // Function to find a function by Guid
    const findFunctionByGuid = (guid) => {
        return functions.find(func => func.Guid === guid);
    };

    const addFunction = (newFunction) => {
        const existingFunction = findFunctionByGuid(newFunction.Guid);
        if (existingFunction) {
            // Update the existing function
            setFunctions(functions.map(func =>
                func.Guid === newFunction.Guid ? { ...func, ...newFunction } : func
            ));
        } else {
            // Add a new function
            setFunctions([...functions, newFunction]);
        }
    };

    // Function to update a function by Guid
    const updateFunctionByGuid = (guid, updatedFunction) => {
        setFunctions(functions.map(func =>
            func.Guid === guid ? { ...func, ...updatedFunction } : func
        ));
    };

    // Function to delete a function by Guid
    const deleteFunctionByGuid = (guid) => {
        setFunctions(functions.filter(func => func.Guid !== guid));
    };

    // Function to populate functions from a JSON object
    const populateFunctionsFromJson = (json) => {
        try {
            const parsedFunctions = JSON.parse(json);
            if (Array.isArray(parsedFunctions)) {
                setFunctions(parsedFunctions);
            } else {
                console.error('Invalid JSON format: Expected an array');
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    };

    // Function to convert functions to a JSON string
    const convertFunctionsToJson = () => {
        return JSON.stringify(functions, null, 2);
    };

    return (
        <FunctionsContext.Provider value={{
            functions,
            getAllFunctions,
            findFunctionByGuid,
            addFunction,
            updateFunctionByGuid,
            deleteFunctionByGuid,
            populateFunctionsFromJson,
            convertFunctionsToJson
        }}>
            {children}
        </FunctionsContext.Provider>
    );
};

/*
import React from 'react';
import { FunctionsProvider, useFunctions } from './FunctionsContext';

const MyComponent = () => {
    const { getAllFunctions, addFunction, deleteFunctionByGuid } = useFunctions();

    // Add a new function example
    const newFunction = {
        Name: 'John Doe',
        Guid: '12345',
        VariableType: 'String'
    };

    return (
        <div>
            <button onClick={() => addFunction(newFunction)}>Add Function</button>
            <button onClick={() => console.log(getAllFunctions())}>Show All Functions</button>
            <button onClick={() => deleteFunctionByGuid('12345')}>Delete Function</button>
        </div>
    );
};

const App = () => (
    <FunctionsProvider>
        <MyComponent />
    </FunctionsProvider>
);

export default App;
*/