// context/SessionContext.js
import React, { createContext, useState, useMemo } from 'react';

// Create a context for session data
export const SessionContext = createContext();

// Create a provider component
export const SessionProvider = ({ children }) => {
  const [sessionData, setSessionData] = useState({
    user: null,  // Initially no user is logged in
    sessionId: null,  // No session ID initially
    isAuthenticated: false  // Boolean flag for authentication status
  });

  // Memoize the value to prevent unnecessary re-renders
  const value = useMemo(() => ({ sessionData, setSessionData }), [sessionData]);

  return (
    <SessionContext.Provider value={value}>
      {children}
    </SessionContext.Provider>
  );
};
